import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Button, Input } from 'reactstrap';
import FullScreenSpinner from '../components/FullScreenSpinner';
import { useHistory } from 'react-router-dom';
import Octicon, { Plus, Pencil } from '@primer/octicons-react';
import * as firebase from 'firebase/app';
import LoaderButton from '../components/LoaderButton';
import { AppContext } from '../App';

function Properties(props) {
  const setError = useContext(AppContext).setError;

  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addNew, setAddNew] = useState(false);

  useEffect(() => {
    //Get assigned properties of manager
    getProperties();
    // eslint-disable-next-line
  }, []);

  const getProperties = () => {
    const db = firebase.firestore();
    const propertiesCollection = db.collection('properties');
    propertiesCollection.where("managers", "array-contains", firebase.auth().currentUser.uid).get()
      .then((querySnapshot) => {
        let receivedProperties = []
        querySnapshot.forEach(function (doc) {
          const property = {
            id: doc.id,
            ...doc.data()
          }
          receivedProperties.push(property);
        });
        setProperties(receivedProperties);
      }).catch((error) => {
        console.log(error);
        setError("Failed to load properties. Try again.");
      }).finally(() => setLoading(false));
  }

  const getUpdatedProperties = () => {
    setAddNew(false);
    getProperties();
  }

  const handleAddNew = () => setAddNew(true);

  const handleCancelAddNew = () => setAddNew(false);

  const addNewButton = () => {
    return (
      <div className="d-flex justify-content-center align-items-center mr-3 mb-3" style={{ width: "200px", height: "200px" }}>
        <Button color="primary" onClick={handleAddNew}><Octicon width={10} icon={Plus} verticalAlign="middle" /> Add property</Button>
      </div>
    );
  }

  if (loading) {
    return (
      <FullScreenSpinner />
    );
  }

  return (
    <div className="d-flex flex-wrap">
      {properties.map((property, index) => {
        return (
          <PropertyCard key={index} property={property} updated={getUpdatedProperties} />
        );
      })}
      {addNew ? <EditProperty property={{id: "new"}} updated={getUpdatedProperties} onCancel={handleCancelAddNew} /> : addNewButton()}
    </div>
  );
}

function EditProperty(props) {
  const setError = useContext(AppContext).setError;

  const [propName, setPropName] = useState((props.property && props.property.name) || "");
  const [propAddress, setPropAddress] = useState((props.property && props.property.address) || "");
  const [loading, setLoading] = useState(false);

  const updateProperty = () => {
    if (!props.property || !props.property.id) {
      return;
    }
    if (props.property.id === "new") {
      addNewProperty();
      return;
    }

    setLoading(true);
    const submittedPropData = {
      name: propName,
      address: propAddress
    }
    const db = firebase.firestore();
    const propertiesCollection = db.collection('properties');
    propertiesCollection.doc(props.property.id).set({
      name: submittedPropData.name,
      address: submittedPropData.address,
      managers: [firebase.auth().currentUser.uid]
    }, {merge: true}).then((docRef) => {
      props.updated();
      setPropName("");
      setPropAddress("");
    }).catch((error) => {
      console.log(error);
      setError("Failed to update property. Try again.");
    }).finally(() => setLoading(false));
  }

  const addNewProperty = () => {
    setLoading(true);
    const submittedPropData = {
      name: propName,
      address: propAddress
    }
    const db = firebase.firestore();
    const propertiesCollection = db.collection('properties');
    propertiesCollection.add({
      name: submittedPropData.name,
      address: submittedPropData.address,
      managers: [firebase.auth().currentUser.uid],
      createdAt: firebase.firestore.Timestamp.now()
    }).then(() => {
      props.updated();
      setPropName("");
      setPropAddress("");
    }).catch((error) => {
      console.log(error);
      setError("Failed to add property. Try again.");
    }).finally(() => setLoading(false));
  }

  const handleChangePropName = (e) => {
    setPropName(e.target.value);
  }

  const handleChangePropAddress = (e) => {
    setPropAddress(e.target.value);
  }

  return (
    <Card className="mr-3 mb-3" style={{ width: "200px", height: "200px" }}>
      <CardBody>
        <CardTitle tag="h2" className="display mb-4"><Input placeholder="Property Name" value={propName} onChange={handleChangePropName} /></CardTitle>
        <CardSubtitle className="text-muted"><Input placeholder="Property Address" value={propAddress} onChange={handleChangePropAddress} /></CardSubtitle>
        <div className="d-flex mt-3">
          <LoaderButton color="primary" className="w-75 mr-1" onClick={updateProperty} loading={loading}>Save</LoaderButton>
          <Button onClick={props.onCancel} color="danger" className="w-25">
            <span>&times;</span>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}

function PropertyCard(props) {
  let history = useHistory();
  const [edit, setEdit] = useState(false);
  
  const handleEdit = (e) => {
    e.stopPropagation();
    setEdit(true)
  };

  const handleCancel = () => {
    setEdit(false);
  }

  const updated = () => {
    handleCancel();
    props.updated();
  }

  if (edit) {
    return (
      <EditProperty
        property={props.property}
        updated={updated}
        onCancel={handleCancel} />
    );
  }

  return (
    <Card onClick={() => history.push(`/property/${props.property.id}`)} className="mr-3 mb-3" style={{ width: "200px", height: "200px", cursor: "pointer" }}>
      <CardBody>
        <CardTitle className="d-block mb-4">
          <h2>{props.property.name}</h2>
          <span onClick={handleEdit} className="text-primary">Edit <Octicon width={10} icon={Pencil} className="ml-1" verticalAlign="middle" /></span>
        </CardTitle>
        <CardSubtitle className="text-muted">{props.property.address}</CardSubtitle>
      </CardBody>
    </Card>
  );
}

export default Properties;