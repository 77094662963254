import React, { useEffect, Fragment, useState, useContext } from 'react';
import { Table } from 'reactstrap';
import Octicon, { Plus } from '@primer/octicons-react';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as firebase from 'firebase/app';
import FullScreenSpinner from '../components/FullScreenSpinner';
import FullScreenNoData from '../components/FullScreenNoData';
import { AppContext } from '../App';

function Rooms() {
  const setError = useContext(AppContext).setError;

  let history = useHistory();
  let propertyId = useParams().id;
  const [propertyName, setPropertyName] = useState("");
  const [rooms, setRooms] = useState([]);
  const [loadingTitle, setLoadingTitle] = useState(true);
  const [loadingRooms, setLoadingRooms] = useState(true);

  useEffect(() => {
    //Get property name
    const db = firebase.firestore();
    const propertyCollection = db.collection("properties");
    propertyCollection.doc(propertyId)
      .get().then((docSnapshot) => {
        const receivedProp = docSnapshot.data();
        if (receivedProp) {
          setPropertyName(receivedProp.name)
        }
      }).catch((error) => {
        console.log(error);
        setError("Failed to load list. Try again.");
      }).finally(() => setLoadingTitle(false));

    //Get rooms in this property
    const roomsCollection = db.collection("rooms");
    roomsCollection.where("inProperty", "==", propertyId)
      .get().then((querySnapshot) => {
        let receivedRooms = []
        querySnapshot.forEach((doc) => {
          const room = {
            id: doc.id,
            ...doc.data()
          }
          receivedRooms.push(room);
        });
        setRooms(receivedRooms);
      }).catch((error) => {
        console.log(error);
        setError("Failed to load list. Try again.");
      }).finally(() => setLoadingRooms(false));
    // eslint-disable-next-line
  }, []);

  const openRoom = (id) => {
    history.push(`/property/${propertyId}/room/${id}`);
  }

  const addNewButton = () => {
    return (
        <Link to={`/property/${propertyId}/room/new`} className="btn btn-primary"><Octicon width={10} icon={Plus} verticalAlign="middle" /> Add Room</Link>
    );
  }

  if (loadingTitle || loadingRooms) {
    return <FullScreenSpinner />;
  }

  if (rooms.length === 0) {
    return <FullScreenNoData button={addNewButton} />;
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="display mb-4">{`Rooms in ${propertyName}`}</h3>
        { addNewButton() }
      </div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Room No.</th>
            <th>Floor</th>
            <th>Occupied By</th>
            <th>Rent</th>
          </tr>
        </thead>
        <tbody>
          {rooms.map((room, index) => {
            return (
              <tr onClick={() => openRoom(room.id)} key={index}>
                <td>{room.roomNo}</td>
                <td>{room.floor}</td>
                <td>{room.occupiedBy}</td>
                <td>{room.rent}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}

export default Rooms;