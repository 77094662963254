import React, { Fragment, useState, useEffect } from 'react';
import { Card, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useHistory  } from "react-router-dom";
import * as firebase from 'firebase/app';
import LoaderButton from '../components/LoaderButton';

function Login(props) {
  const db = firebase.firestore();
  let history = useHistory();

  firebase.auth().useDeviceLanguage();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('catpcha-container', {
      'size': 'invisible',
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendOtp = (e) => {
    e.preventDefault();
    if (isNaN(mobileNumber)) {
      console.error("Not a number");
      return;
    }

    if (mobileNumber.length < 4) {
      console.error("Mobile length must be greater than 4");
      return;
    }

    setSending(true);
    firebase.auth().signInWithPhoneNumber(`+${mobileNumber}`, window.recaptchaVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setError("");
        setOtpSent(true);
      }).catch(function (error) {
        // Error; SMS not sent   
        if (window.grecaptcha) {
          window.grecaptcha.reset(window.recaptchaWidgetId);
        }
        setError("Failed to send OTP");
      }).finally(() => setSending(false));
  }

  const verifyOtp = (e) => {
    e.preventDefault();
    if (isNaN(mobileNumber)) {
      console.error("Not a number");
      return;
    }

    setVerifying(true);
    window.confirmationResult.confirm(otp)
      .then(function (result) {
        // User signed in successfully.
        const managersCollection = db.collection('managers');
        const manager = managersCollection.doc(result.user.uid);
        return manager.get();
      }).then(() => {
        history.push('/property');
      }).catch(function (error) {
        if (error.code === "permission-denied") {
          setError("You don't have permission to access this page");
        } else {
          setError("Failed to sign in. Try again.")
        }
        console.log(error);
        setVerifying(false);
      });

  }

  const handleChangeNumber = (e) => {
    setMobileNumber(e.target.value);
  }

  const handleChangeOtp = (e) => {
    setOtp(e.target.value);
  }

  const GetNumber = () => {
    return (
      <Fragment>
        <h1 className="display text-center p-2">Login</h1>
        <form className="text-center" onSubmit={sendOtp}>
          <InputGroup className="m-2">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>+</InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Enter Mobile Number" type="text" value={mobileNumber} onChange={handleChangeNumber} />
          </InputGroup>
          <div id="catpcha-container"></div>
          <LoaderButton id="sign-in-button" className="m-2 w-100" onClick={sendOtp} loading={sending}>Send OTP</LoaderButton>
        </form>
      </Fragment >
    )
  }

  const VerifyOTP = () => {
    return (
      <Fragment>
        <h1 className="display text-center p-2">Enter OTP</h1>
        <form className="text-center" onSubmit={verifyOtp}>
          <InputGroup className="m-2">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>OTP</InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Enter OTP" type="text" value={otp} onChange={handleChangeOtp} />
          </InputGroup>
          <LoaderButton id="sign-in-button" type="submit" className="m-2 w-100" onClick={verifyOtp} loading={verifying}>Verify OTP</LoaderButton>
        </form>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="row h-100 w-100 justify-content-center align-items-center">
        <Card className="form-box p-4 justify-content-center" >
          {otpSent ? VerifyOTP() : GetNumber()}
          <p style={{ height: "24px" }} className="text-danger text-center m-0">{error}</p>
        </Card>
      </div>
    </Fragment>
  );
}



export default Login;