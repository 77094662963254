import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import * as firebase from 'firebase/app';
import { AppContext } from '../App';
import LoaderButton from '../components/LoaderButton';

function RoomDetails() {
  let history = useHistory();
  let roomId = useParams().roomId;
  let propertyId = useParams().id;
  const setError = useContext(AppContext).setError;
  const setSuccess = useContext(AppContext).setSuccess;
  const [roomNumber, setRoomNumber] = useState("");
  const [floor, setFloor] = useState("");
  const [rentee, setRentee] = useState("");
  const [rent, setRent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoomDetails();
    // eslint-disable-next-line
  }, []);

  const getRoomDetails = () => {
    if (!roomId || roomId === "new") {
      setLoading(false);
      return;
    }

    const db = firebase.firestore();
    const roomsCollection = db.collection('rooms');
    roomsCollection.doc(roomId).get()
      .then((documentSnapshot) => {
        setRoomNumber(documentSnapshot.data().roomNo);
        setFloor(documentSnapshot.data().floor);
        setRentee(documentSnapshot.data().occupiedBy);
        setRent(documentSnapshot.data().rent);
      }).catch((error) => {
        console.log(error);
        setError("Failed to load details. Try again.");
      }).finally(() => setLoading(false));
  }

  const saveRoomDetails = (e) => {
    e.preventDefault();

    setLoading(true);
    if (!roomId) {
      setError("Invalid Room ID");
      return;
    }

    if (roomId === "new") {
      createNewRoom();
      return;
    }

    const db = firebase.firestore();
    const roomsCollection = db.collection('rooms');
    roomsCollection.doc(roomId).set({
      roomNo: roomNumber,
      floor,
      occupiedBy: rentee,
      rent
    }, { merge: true })
      .then(() => {
        setSuccess("Room details have been saved.");
      }).catch((error) => {
        console.log(error);
        setError("Failed to load details. Try again.");
      }).finally(() => setLoading(false));
  }

  const createNewRoom = () => {
    console.log(propertyId);
    const db = firebase.firestore();
    const roomsCollection = db.collection('rooms');
    roomsCollection.add({
      roomNo: roomNumber,
      floor,
      occupiedBy: rentee,
      rent,
      inProperty: propertyId,
      createdAt: firebase.firestore.Timestamp.now()
    }).then((docRef) => {
      history.replace(`/property/${propertyId}/room/${docRef.id}`);
      setSuccess("Room created successfully.");
    }).catch((error) => {
      console.log(error);
      setError("Failed to create room. Try again.");
    }).finally(() => setLoading(false));
  }

  const handleRoomNumberChange = (e) => {
    setRoomNumber(e.target.value);
  }

  const handleFloorChange = (e) => {
    setFloor(e.target.value);
  }

  const handleRenteeChange = (e) => {
    setRentee(e.target.value);
  }

  const handleRentChange = (e) => {
    setRent(e.target.value);
  }

  return (
    <div>
      <h3 className="display mb-4">{roomId === "new" ? "Add New Room" : "Edit Room Detail"}</h3>
      <Form className="d-flex flex-column " style={{ maxWidth: "440px" }}>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Room Number</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="Enter room number" value={roomNumber} onChange={handleRoomNumberChange} />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Floor</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="Enter floor name" value={floor} onChange={handleFloorChange} />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Rentee</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="Enter rentee's number" value={rentee} onChange={handleRenteeChange} />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Rent</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="Enter rent" value={rent} onChange={handleRentChange} />
        </InputGroup>
        <LoaderButton onClick={saveRoomDetails} color="primary" type="submit" loading={loading}>Save</LoaderButton>
      </Form>
    </div>
  );
}

export default RoomDetails;