import React from 'react';

function FullScreenNoData(props) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "80vh" }} >
      <div className="mb-2">
        <span role="img" aria-label="confused">😕</span>
        <span className="ml-2"><b>Nothing to display. Make some?</b></span>
      </div>
      {props.button ? props.button() : null}
    </div>
  );
}

export default FullScreenNoData;