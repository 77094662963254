import React, { Fragment } from 'react';
import Header from '../components/Header';
import { Switch, Route } from 'react-router-dom';
import Properties from './Properties';
import Rooms from './Rooms';
import RoomDetails from './RoomDetails';

function Dashboard() {
  return (
    <Fragment>
      <Header />
      <div className="p-4">
        <Switch>
          <Route exact path="/property">
            <Properties />
          </Route>
          <Route exact path="/property/:id">
            <Rooms />
          </Route>
          <Route exact path="/property/:id/room/:roomId">
            <RoomDetails />
          </Route>
        </Switch>
      </div>
    </Fragment>);
}

export default Dashboard;